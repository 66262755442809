@media screen and (min-width: 768px) and (max-width: 1023px) {
  .FlexContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding-top: 64px;
    overflow-x: hidden;
    position: relative;
  }

  .Layout_1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    text-align: center;
  }

  .Layout_1_Contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    left: 30%;
    top: 4%;
    transform: translate(-50%, -50%);
    z-index: 2;
  }

  .Layout_1_Text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }

  .Layout_1_Illust {
    position: relative;
    max-height: 1080px;
    overflow: hidden;
    z-index: 1;
  }

  .Layout_1_ButtonCT {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    text-align: center;
  }

  .Layout_1_Apple {
    display: flex;

    margin-bottom: 5%;
    margin-right: 5%;
  }

  .Layout_1_Google {
    display: flex;
  }

  .Layout_1_ButtonBG {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    background: rgb(255, 255, 255);
    border: none;
    border-radius: 6px;

    height: 48px;
    width: 160px;
  }

  .Layout_2 {
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    /* min-width: 906px; */
    margin-top: 160px;
  }

  .Layout_2_PainText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 120px;
  }

  .Layout_2_SolutionText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 180px;
  }

  .Layout_2_GrayBG {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    background-color: rgb(255, 255, 255);

    height: auto;
    width: 100%;
    margin-top: 50px;

    overflow: hidden;
  }

  .Layout_2_Image {
    display: flex;
    flex-direction: row;

    max-width: 100%;
    margin-bottom: 15%;
    margin-left: 10%;

    animation-name: Layout_2_Image_Anime; /* 애니메이션 이름 설정 */
    animation-duration: 20s; /* 애니메이션 지속 시간 설정 */
    animation-timing-function: linear; /* 애니메이션 타이밍 함수 설정 */
    animation-fill-mode: forwards; /* 애니메이션 종료 후 유지되도록 설정 */
  }

  @keyframes Layout_2_Image_Anime {
    0% {
      transform: translate(0%);
    }

    50% {
      transform: translate(-454%);
    }

    100% {
      transform: translate(-908%);
    }
  }

  .Layout_3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20%;
    margin-bottom: 20%;
    text-align: center;
  }

  .Layout_3_Text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
  }

  .Layout_3_SubText {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    max-width: 480px;
  }

  .Layout_3_Images {
    position: relative;
    height: 50%;
  }

  .Layout_4 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20%;
  }

  .Layout_4_Contents {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }

  .Layout_4_Images {
    display: flex;
    order: 1;
  }

  .Layout_4_Text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    order: 0;
  }

  .Layout_4_SubText {
    display: flex;
    flex-direction: column;
    margin-top: 3%;

    max-width: 480px;
  }

  .Layout_5 {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .Layout_5_Text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
  }

  .Layout_5_SubText {
    display: flex;
    flex-direction: column;
    margin-top: 3%;

    max-width: 360px;
  }

  .Layout_5_Images {
    display: flex;
    order: 2;
  }

  .Layout_6 {
    background-color: rgb(247, 249, 250);
    display: flex;
    flex-direction: column;
    margin-top: 20%;
    align-items: center;
    text-align: center;
    justify-content: flex-start;
    height: auto;
    width: 100%;
  }

  .Layout_6_Text {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-top: 30%;
    opacity: 0;
  }

  .Layout_6_SubText {
    display: flex;
    flex-direction: column;
    margin-top: 3%;
    max-width: 260px;
  }

  .Layout_6_Images {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 128px;
  }

  .Layout_6_Image_CT_1 {
    display: flex;
    flex-direction: column;
    max-width: 520px;
  }

  .Layout_6_Image_CT_2 {
    display: flex;
    flex-direction: column;
    max-width: 520px;
  }

  .Layout_7 {
    display: flex;
    flex-direction: column;
    background: linear-gradient(
      138.69deg,
      #7ee5c3 0%,
      #3769fc 52.6%,
      #153383 100%
    );
    justify-content: center;
    align-items: center;
    z-index: 1;
    width: 100%;
    height: auto;
    max-height: 600px;
    margin-bottom: 15%;
  }

  .Layout_7_Content {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    z-index: 2;
    margin-top: 15%;
  }

  .Layout_7_Button {
    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: center;
    margin-top: 15%;
    margin-bottom: 15%;
  }

  .Layout_7_Apple {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-bottom: 5%;
  }

  .Layout_7_Google {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    margin-bottom: 5%;
  }

  .Layout_7_ButtonBG {
    background: rgb(255, 255, 255);
    border: none;
    border-radius: 6px;
    height: 56px;
    width: 200px;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding: 0px;
    margin: 0px;
  }

  .Layout_8 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .Layout_8_Content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 84%;
  }

  .Layout_8_TitleCT {
    display: flex;
    flex-direction: column;
  }

  .Layout_8_TitleBG {
    background-image: url("../src/Assets/Images/Fig1.png");
    height: 59px;
    width: 197px;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  .Layout_8_Text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 660px;
    margin-top: 48px;
  }

  .Layout_8_LineCT {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .Layout_8_Line {
    background-color: rgb(202, 202, 202);
    display: flex;
    flex-direction: column;
    height: 1px;
    margin-top: 24px;
    margin-bottom: 24px;
    align-items: center;
    width: 100%;
  }

  .Layout_9 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: 120px;
    width: 84%;
  }

  .Layout_9_Line {
    display: flex;
    width: 100%;
    height: 1px;
    background-color: rgb(148, 186, 253);
    margin-top: 64px;
    margin-bottom: 64px;
  }

  .Layout_9_Title {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .Layout_9_Logo {
    display: flex;
    justify-content: center;
  }

  .Layout_9_Text {
    display: flex;
    flex-direction: column;
  }

  .Layout_Header {
    position: fixed;
    z-index: 3;
    box-shadow: none;
    top: 0px;
    width: 100%;
    height: 64px;
    background-color: rgb(255, 255, 255);
  }

  .Layout_Header_Logo {
    position: fixed;
    z-index: 1;
    width: 97.2px;
    height: 22.8px;
    top: 24px;
    left: 8%;
  }

  .Copy_1_200_A {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 200;
    font-size: 48px;
    text-align: center;
    word-break: keep-all;
    line-height: 140%;
  }

  .Copy_1_200_B {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    text-align: center;
    word-break: keep-all;
    line-height: 140%;
  }

  .Copy_2_600_A {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    text-align: center;
    line-height: 130%;
    word-break: keep-all;
  }

  .Copy_3_700_A {
    color: rgb(255, 255, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    word-break: keep-all;
  }

  .Copy_4_700_A {
    color: rgb(0, 102, 255);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 140%;
    word-break: keep-all;
  }

  .Copy_5_500_A {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    justify-content: center;
    text-align: center;
  }

  .Copy_6_500_B {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 150%;
    margin-top: 16px;
    word-break: keep-all;
  }

  .Copy_6_400_A {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    text-align: center;
    word-break: keep-all;
    text-align: center;
    line-height: 130%;
    margin-top: 15%;
    margin-bottom: 10%;
  }

  .Copy_6_400_B {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-size: 20px;
    font-weight: 500;
    line-height: 150%;
    text-align: center;
    word-break: keep-all;
  }

  .Copy_6_500_A {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    justify-content: center;
    text-align: center;
  }

  .Copy_6_500_B {
    color: rgb(70, 70, 70);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin-top: 16px;
    word-break: keep-all;
  }

  .Copy_6_500_C {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
  }

  .Copy_7_400_A {
    color: rgb(61, 61, 61);
    font-family: "Pretendard";
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    margin-left: 30px;
    margin-right: 30px;
    line-height: 150%;
  }
}
